import React, { useState } from 'react';
import { IconMail, IconPhonePlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import Toast from './toast';

export default function JoinNow() {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [toast, setToast] = useState({
        message: '',
        type: '',
        visible: false
    });

    const { t } = useTranslation();
    const { header, content, button } = t("joinnow");

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch('https://officialbackend.taqnura.com/contactus/sent-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setToast({
                    message: 'Email sent successfully!',
                    type: 'success',
                    visible: true
                });
                setFormData({ name: '', email: '', message: '' });
            } else {
                setToast({
                    message: 'Error! Please try again.',
                    type: 'error',
                    visible: true
                });
            }
        } catch (error) {
            setToast({
                message: 'An error occurred. Please try again later.',
                type: 'error',
                visible: true
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            {/* Show Toast if visible */}
            {toast.visible && (
                <Toast
                    type={toast.type}
                    message={toast.message}
                    onClose={() => setToast((prev) => ({ ...prev, visible: false }))}
                />
            )}

            {/* Main Content */}
            <div className="bg-[rgba(217,217,217,0.3)] p-5 md:mx-28 mx-5 md:mt-24 mt-10 flex flex-wrap font-lato rounded-lg border-2 border-gray-500">
                {/* Image Side */}
                <div className="imageSide md:w-1/2 flex justify-center items-center min-w-[200px] w-full">
                    {!isImageLoaded && (
                        <div className="skeleton-loader bg-gray-300 w-[250px] h-[150px] md:w-[350px] md:h-[250px] animate-pulse rounded-lg"></div>
                    )}
                    <img
                        src="/assets/joinPhoto.png"
                        alt="joinphoto"
                        className={`md:max-w-[350px] max-w-[250px] ${isImageLoaded ? 'block' : 'hidden'}`}
                        onLoad={() => setIsImageLoaded(true)}
                    />
                </div>

                {/* Content Side */}
                <div className="contentSide md:w-1/2 text-white flex flex-col gap-3 justify-center items-start min-w-[200px]">
                    <h1 className="font-light text-base md:text-3xl text-left">
                        {header.white} <span className="text-blue-400">{header.blue}.</span>
                    </h1>

                    <p className="text-left font-light sm:text-sm text-xs md:pe-8 pe-2">
                        {content}
                    </p>

                    <a href="mailto:info@taqnura.com" className="hover:text-blue-400 flex gap-3 text-sm font-light">
                        <IconMail stroke={1} />
                        <span>info@taqnura.com</span>
                    </a>

                    <a href="tel:+918891341818" className="hover:text-blue-400 flex gap-3 text-sm font-light">
                        <IconPhonePlus stroke={1} />
                        <span>+91 8891341818</span>
                    </a>

                    {/* Contact Form */}
                    <form onSubmit={handleSubmit} className="flex flex-col space-y-2 w-full mt-5">
                        <div className="flex space-x-2 w-full">
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="w-1/2 h-8 bg-transparent border-2 text-sm placeholder:text-gray-400 md:text-base border-slate-500 py-1 px-2"
                                placeholder="Your Name ..."
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-1/2 h-8 bg-transparent border-2 text-sm md:text-base placeholder:text-gray-400 border-slate-500 py-1 px-2"
                                placeholder="example@gmail.com"
                                required
                            />
                        </div>

                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            placeholder="Add message here..."
                            className="h-auto placeholder:text-gray-400 bg-transparent border-2 text-sm md:text-base border-slate-500 py-1 px-2"
                            rows={1}
                            required
                        ></textarea>

                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="px-2 py-1 bg-blue-700 border-2 border-slate-500 text-sm md:text-base xl:px-5 hover:bg-blue-800 transition-colors duration-300"
                        >
                            {isSubmitting ? button.sending : button.default}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}
