import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../home/Footer';
import { useTranslation } from 'react-i18next';

export default function Solutions() {
 
  const { t } = useTranslation();
  const {header, content, card } = t("solution");

  const cards = [
    {
      id: 1,
      title: card.erp.header,
      image: "/assets/errp.jpg",
      description: card.erp.description
    },
    {
      id: 2,
      title: card.ecom.header,
      image: "/assets/isometric.jpg",
      description: card.ecom.description
    },
    {
      id: 3,
      title: card.pos.header,
      image: "/assets/pos_Thumbnail.jpg",
      description: card.pos.description
    },
    {
      id: 4,
      title: card.digital.header,
      image: "/assets/internet.jpg",
      description: card.digital.description
    },
    {
      id: 5,
      title: card.apps.header,
      image: "/assets/mobileapp.jpg",
      description: card.apps.description
    },
    {
      id: 6,
      title: card.hrms.header,
      image: "/assets/hrms1.png",
      description: card.hrms.description
    }
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='text-white min-h-screen pt-[100px] px-7 md:px-20'>
      <div className='md:px-12'>
        <h1 className='font-lato font-light text-xl sm:text-3xl lg:text-4xl text-center'>{header}</h1>
        <p className='md:mt-6 mt-4 font-light text-xs md:text-xl text-justify sm:text-center font-josefin text-gray-300'>
         {content}
        </p>
      </div>

      <div className='card_container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-x-6 md:gap-y-12 gap-x-6 gap-y-16 mt-10 md:mt-24'>
        {cards.map((card) => (
          <Card key={card.id} card={card} />
        ))}
      </div>

      <Footer />
    </div>
  );
}

function Card({ card }) {

  const { t } = useTranslation();
  const {learnmore} = t("solution");

  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); // Track image loading state
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Trigger animation once per scroll
        }
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={cardRef}
      className={`flex flex-col md:p-5 p-3 border border-gray-500 rounded-2xl md:pb-8 pb-5 bg-[rgba(217,217,217,0.1)] 
      transition-transform duration-700 ease-out
      ${isVisible ? 'transform translate-y-0 opacity-100' : 'transform translate-y-20 opacity-0'}`}
    >
      <div className="relative">
        {!isLoaded && (
          // Skeleton or placeholder
          <div className="animate-pulse bg-gray-200 h-[200px] md:h-[250px] lg:h-[300px] w-full rounded-xl"></div>
        )}
        <img
          src={card.image}
          alt={card.title}
          className={`rounded-xl w-full ${isLoaded ? 'block' : 'hidden'}`}
          onLoad={() => setIsLoaded(true)}
          style={{ maxHeight: '300px', objectFit: 'cover' }} // Adjust max height as per design
        />
      </div>
      <div className='flex flex-col gap-4 font-josefin font-light mt-6'>
        <h2 className='text-left'>{card.title}</h2>
        <p className='text-justify text-base text-gray-300'>{card.description}</p>
        <Link
          to={`/${card.title.replace(/\s+/g, '-').toLowerCase()}/solutions?item=${card.id}`}
          className="cta flex items-center self-end"
        >
          <span className='font-light font-josefin text-sm'>{learnmore}</span>
          <svg width="15px" height="10px" viewBox="0 0 13 10">
            <path d="M1,5 L11,5"></path>
            <polyline points="8 1 12 5 8 9"></polyline>
          </svg>
        </Link>
      </div>
    </div>
  );
}
