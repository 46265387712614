import React, { useEffect, useState } from "react";

export default function DataModal({ id, onClose, markAsReadInList }) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isClosing, setIsClosing] = useState(false); // Track closing animation state

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("accessToken");

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/admin/demo/demo-request/${id}`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                const result = await response.json();

                if (response.ok && result.success) {
                    setData(result.data);
                    if (result.data.is_read === false) {
                        await markAsRead(id);
                        markAsReadInList(id);
                    }
                }
            } catch (err) {
                console.error("Error fetching modal data:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const markAsRead = async (id) => {
        const token = localStorage.getItem("accessToken");
        try {
            await fetch(
                `${process.env.REACT_APP_API_URL}/api/admin/demo/demo-request-mark-as-read/${id}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
        } catch (err) {
            console.error("Error marking as read:", err);
        }
    };

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
        }, 300); // Match this to your animation duration
    };

    // Skeleton Loader
    const SkeletonLoader = () => (
        <div className="space-y-4">
            <div className="h-6 bg-gray-300 animate-pulse w-3/4"></div>
            <div className="h-4 bg-gray-300 animate-pulse w-1/2"></div>
            <div className="h-4 bg-gray-300 animate-pulse w-2/3"></div>
            <div className="h-4 bg-gray-300 animate-pulse w-1/4"></div>
            <div className="h-6 bg-gray-300 animate-pulse w-3/4"></div>
            <div className="h-4 bg-gray-300 animate-pulse w-1/2"></div>
            <div className="h-4 bg-gray-300 animate-pulse w-2/3"></div>
            <div className="h-4 bg-gray-300 animate-pulse w-1/4"></div>
            <div className="h-4 bg-gray-300 animate-pulse w-1/4"></div>
        </div>
    );

    return (
        <div
            className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out ${
                isClosing ? "opacity-0" : "opacity-100"
            }`}
            onClick={handleClose}
        >
            <div
                className={`bg-white rounded-lg shadow-lg p-6 w-[90%] max-w-md sm:max-w-lg md:w-[80%] lg:w-[60%] xl:w-[50%] transform transition-all duration-300 ease-in-out ${
                    isClosing ? "scale-95 opacity-0" : "scale-100 opacity-100"
                }`}
                onClick={(e) => e.stopPropagation()}
            >
                {loading ? (
                    <SkeletonLoader />
                ) : data ? (
                    <div className="flex justify-start flex-col font-lato sm:px-5 items-start space-y-1">
                        <h2 className="text-xl font-semibold text-gray-800">{data.company_name}</h2>
                        <table className="w-full text-left text-sm">
                            <tbody>
                                <tr>
                                    <td className="text-gray-500 font-medium">Industry:</td>
                                    <td className="pl-4 text-gray-700">{data.industry}</td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500 font-medium">Contact:</td>
                                    <td className="pl-4 text-gray-700">{data.contact_name}</td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500 font-medium">Email:</td>
                                    <td className="pl-4 text-gray-700">{data.email}</td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500 font-medium">Phone:</td>
                                    <td className="pl-4 text-gray-700">{data.mobile}</td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500 font-medium">City:</td>
                                    <td className="pl-4 text-gray-700">{data.city}</td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500 font-medium">Country:</td>
                                    <td className="pl-4 text-gray-700">{data.country}</td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500 font-medium">Description:</td>
                                    <td className="pl-4 text-gray-700">{data.company_description}</td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500 font-medium">Interests:</td>
                                    <td className="pl-4 text-gray-700">{data.product_interests}</td>
                                </tr>
                                <tr>
                                    <td className="text-gray-500 font-medium">Created At:</td>
                                    <td className="pl-4 text-gray-700">
                                        {new Date(data.created_at).toLocaleString()}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="text-gray-700">Failed to load data.</p>
                )}

                <div className="flex justify-end mt-6">
                    <button
                        className="bg-red-500 text-white px-6 py-2 rounded-md text-sm shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
                        onClick={handleClose}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}
