import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  GetCountries,
  GetState,
  GetCity
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const Modal = ({ isOpen, closeModal, formData, handleSubmitForm }) => {
  // State for country, state, city selection
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  // Selected values with initial state
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  // Toast states
  const [toastType, setToastType] = useState(null);
  const [toastVisible, setToastVisible] = useState(false);

  // Form initialization
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset, // Added reset method
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      company_name: "",
      industry: "",
      web: "",
      email: "",
      contact_name: "",
      designation: "",
      mobile: "",
      whatsapp: "",
      product_interests: "",
      company_description: "",
      country: '',
      state: '',
      city: ''
    }
  });

  useEffect(() => {
    if (!isOpen) {
      reset(); // Reset form to default values
      setSelectedCountry(null);
      setSelectedState(null);
      setSelectedCity(null);
      setStateList([]);
      setCityList([]);
    }
  }, [isOpen, reset]);

  // Fetch countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countries = await GetCountries();
        setCountriesList(countries);

        // If formData has a country, pre-select it
        if (formData.country) {
          const preSelectedCountry = countries.find(c => c.name === formData.country);
          if (preSelectedCountry) {
            setSelectedCountry(preSelectedCountry);
            setValue('country', preSelectedCountry.name);

            // Fetch states for pre-selected country
            const states = await GetState(preSelectedCountry.id);
            setStateList(states);

            // If formData has a state, pre-select it
            if (formData.state) {
              const preSelectedState = states.find(s => s.name === formData.state);
              if (preSelectedState) {
                setSelectedState(preSelectedState);
                setValue('state', preSelectedState.name);

                // Fetch cities for pre-selected state
                const cities = await GetCity(preSelectedCountry.id, preSelectedState.id);
                setCityList(cities);

                // If formData has a city, pre-select it
                if (formData.city) {
                  const preSelectedCity = cities.find(c => c.name === formData.city);
                  if (preSelectedCity) {
                    setSelectedCity(preSelectedCity);
                    setValue('city', preSelectedCity.name);
                  }
                }
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
    fetchCountries();
  }, []);

  // Handle country selection
  const handleCountryChange = async (country) => {
    setSelectedCountry(country);
    setSelectedState(null);
    setSelectedCity(null);

    // Clear state and city
    setValue('state', '');
    setValue('city', '');

    // Fetch states for selected country
    try {
      const states = await GetState(country.id);
      setStateList(states);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  // Handle state selection
  const handleStateChange = async (state) => {
    setSelectedState(state);
    setSelectedCity(null);

    // Clear city
    setValue('city', '');

    // Fetch cities for selected state
    try {
      const cities = await GetCity(selectedCountry.id, state.id);
      setCityList(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const onSubmit = async (data) => {
    // Validate required fields
    if (Object.values(data).some((value) => value === "")) {
      setToastType("warning");
      setToastVisible(true);
      setTimeout(() => setToastVisible(false), 3000);
      return;
    }

    try {
      // Prepend country code to mobile and whatsapp numbers if country is selected
      if (selectedCountry) {
        const countryCode = `+${selectedCountry.phone_code}`;
        data.mobile = `${countryCode}${data.mobile}`;
        data.whatsapp = `${countryCode}${data.whatsapp}`;

        // Ensure we're storing only names as strings
        data.country = selectedCountry.name;
        data.state = selectedState ? selectedState.name : '';
        data.city = selectedCity ? selectedCity.name : '';
      }

      await handleSubmitForm(data);

      // Reset form after successful submission
      reset();
      setSelectedCountry(null);
      setSelectedState(null);
      setSelectedCity(null);
      setStateList([]);
      setCityList([]);

      closeModal();
    } catch (error) {
      console.error("Submission error:", error);
      setToastType("error");
      setToastVisible(true);
      setTimeout(() => setToastVisible(false), 3000);
    }
  };

  // Render method
  return (
    isOpen && (
      <div className="modal-overlay md:mt-10 fixed inset-0 z-50 flex items-center justify-center p-4">
        <div className="modal-container 
          bg-gray-500 
          p-4 sm:p-8 
          rounded-lg 
          shadow-lg 
          w-full sm:w-1/2 
          max-w-md 
          transform 
          transition-all 
          duration-500
        ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header flex justify-between items-center mb-4">
              <h2 className="text-lg sm:text-xl text-white font-lighter">Company Details</h2>
              <button
                type="button"
                onClick={closeModal}
                className="text-lg sm:text-xl text-red-300 hover:text-red-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 6l-12 12" />
                  <path d="M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="modal-body grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">

              {/* Other input fields */}
              {Object.keys(formData)
                .filter(key => !['country', 'state', 'city', 'mobile', 'whatsapp'].includes(key))
                .map((key) => (
                  <div key={key} className="input-group flex flex-col">
                    <input
                      {...register(key, {
                        required: `${key.replace(/([A-Z])/g, " $1")} is required`,
                        ...(key === "email" && {
                          pattern: {
                            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
                            message: "Invalid email address",
                          },
                        }),
                      })}
                      id={key}
                      name={key}
                      type="text"
                      placeholder={key.replace(/([A-Z])/g, " $1")}
                      className="w-full py-1 sm:py-1 bg-gray-400 text-white px-2 border placeholder:text-gray-100 rounded-md text-sm sm:text-base"
                    />
                    {errors[key] && <p className="text-red-500 text-xs">{errors[key]?.message}</p>}
                  </div>
                ))}

              {/* Mobile Input with Country Code Prefix */}
              <div className="input-group flex flex-col">
                <div className="flex items-center">
                  {selectedCountry && (
                    <span className="bg-gray-400 text-white px-2 py-1 rounded-l-md">
                      +{selectedCountry.phone_code}
                    </span>
                  )}
                  <input
                    {...register("mobile", {
                      required: "Mobile number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Invalid phone number, must be 10 digits",
                      },
                    })}
                    id="mobile"
                    name="mobile"
                    type="tel"
                    placeholder="Mobile Number"
                    className="w-full py-1 sm:py-1 bg-gray-400 text-white px-2 border placeholder:text-gray-100 rounded-r-md text-sm sm:text-base"
                  />
                </div>
                {errors.mobile && <p className="text-red-500 text-xs">{errors.mobile.message}</p>}
              </div>

              {/* Whatsapp Input with Country Code Prefix */}
              <div className="input-group flex flex-col">
                <div className="flex items-center">
                  {selectedCountry && (
                    <span className="bg-gray-400 text-white px-2 py-1 rounded-l-md">
                      +{selectedCountry.phone_code}
                    </span>
                  )}
                  <input
                    {...register("whatsapp", {
                      required: "Whatsapp number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Invalid phone number, must be 10 digits",
                      },
                    })}
                    id="whatsapp"
                    name="whatsapp"
                    type="tel"
                    placeholder="Whatsapp Number"
                    className="w-full py-1 sm:py-1 bg-gray-400 text-white px-2 border placeholder:text-gray-100 rounded-r-md text-sm sm:text-base"
                  />
                </div>
                {errors.whatsapp && <p className="text-red-500 text-xs">{errors.whatsapp.message}</p>}
              </div>

              {/* Country Dropdown */}
              <div className="input-group flex flex-col">
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: "Country is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      value={selectedCountry ? countriesList.findIndex(c => c.id === selectedCountry.id) : ''}
                      onChange={(e) => {
                        const country = countriesList[e.target.value];
                        field.onChange(country.name);
                        handleCountryChange(country);
                      }}
                      className="w-full py-1 sm:py-1 bg-gray-400 px-2 border placeholder:text-gray-100 rounded-md text-sm sm:text-base"
                    >
                      <option value="">Select Country</option>
                      {countriesList.map((country, index) => (
                        <option key={country.id} value={index}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors.country && <p className="text-red-500 text-xs">{errors.country.message}</p>}
              </div>

              {/* State Dropdown */}
              <div className="input-group flex flex-col">
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: "State is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      value={selectedState ? stateList.findIndex(s => s.id === selectedState.id) : ''}
                      onChange={(e) => {
                        const state = stateList[e.target.value];
                        field.onChange(state.name);
                        handleStateChange(state);
                      }}
                      disabled={!selectedCountry}
                      className="w-full py-1 sm:py-1 bg-gray-400 px-2 border placeholder:text-gray-100 rounded-md text-sm sm:text-base"
                    >
                      <option value="">Select State</option>
                      {stateList.map((state, index) => (
                        <option key={state.id} value={index}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors.state && <p className="text-red-500 text-xs">{errors.state.message}</p>}
              </div>

              {/* City Dropdown */}
              <div className="input-group flex flex-col">
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: "City is required" }}
                  render={({ field }) => (
                    <select
                      {...field}
                      value={selectedCity ? cityList.findIndex(c => c.id === selectedCity.id) : ''}
                      onChange={(e) => {
                        const city = cityList[e.target.value];
                        field.onChange(city.name);
                        setSelectedCity(city);
                      }}
                      disabled={!selectedState}
                      className="w-full py-1 sm:py-1 bg-gray-400 px-2 border placeholder:text-gray-100 rounded-md text-sm sm:text-base"
                    >
                      <option value="">Select City</option>
                      {cityList.map((city, index) => (
                        <option key={city.id} value={index}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  )}
                />
                {errors.city && <p className="text-red-500 text-xs">{errors.city.message}</p>}
              </div>

            </div>

            {/* Modal Footer */}
            <div className="modal-footer flex justify-between items-center mt-4 sm:mt-6">
              <button
                type="button"
                onClick={closeModal}
                className="px-3 py-1 sm:px-4 sm:py-2 bg-gray-600 hover:bg-gray-700 rounded-md text-white text-sm sm:text-base"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-3 py-1 sm:px-4 sm:py-2 bg-blue-900 hover:bg-blue-950 rounded-md text-white text-sm sm:text-base"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default Modal;