import React, { useEffect } from 'react'
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function AnimationDiv() {

    useEffect(() => {
        // GSAP Animation
        gsap.to(".box2", {
            y: 400,
            left: 0,
            rotation: 360,
            duration: 15,
            ease: "power1.inOut",
            repeat: -1,
            yoyo: true,
            scrollTrigger: {
                trigger: ".box2",
                start: "top center",
            },
        });
    }, []);

    return (
        <>
            {/* Background and Cursor Effects */}
            <div className="box2 bg-blue-700 w-[200px] h-[200px] fixed top-0 right-0 z-[150] rounded-full blur-[200px]"></div>
        </>
    )
}
