import React, { useState, useEffect } from 'react';

const Toast = ({ type, message, duration = 3000, onClose }) => {
  const [visible, setVisible] = useState(true);
  const [animating, setAnimating] = useState(false); // Controls both opening and closing animations

  useEffect(() => {
    // Trigger the opening animation after the component mounts
    setAnimating(true);

    // After the specified duration, start the closing animation
    const closeTimer = setTimeout(() => {
      setAnimating(false); // Trigger closing animation
    }, duration);

    // Once the closing animation completes (after 500ms), hide the toast
    const hideTimer = setTimeout(() => {
      setVisible(false); // Remove the toast from the DOM
      onClose && onClose(); // Call onClose callback
    }, duration + 500); // Wait for the closing animation to finish (500ms)

    return () => {
      clearTimeout(closeTimer);
      clearTimeout(hideTimer);
    };
  }, [duration, onClose]);

  const typeStyles = {
    success: {
      bgColor: 'bg-green-50 dark:bg-green-800',
      textColor: 'text-green-600 dark:text-green-200',
      icon: (
        <svg className="shrink-0 size-4 text-green-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
        </svg>
      )
    },
    error: {
      bgColor: 'bg-red-50 dark:bg-red-800',
      textColor: 'text-red-600 dark:text-red-200',
      icon: (
        <svg className="shrink-0 size-4 text-red-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
        </svg>
      )
    }
  };

  const { bgColor, textColor, icon } = typeStyles[type] || typeStyles.success;

  return (
    <div
      className={`fixed top-5 left-1/2 transform -translate-x-1/2 max-w-xs ${bgColor} border border-gray-200 rounded-xl shadow-lg dark:border-neutral-700 z-[1000] transition-transform duration-500 ease-in-out ${animating ? 'translate-y-0' : '-translate-y-full'}`}
      role="alert"
      tabIndex="-1"
    >
      <div className="flex p-4">
        <div className="shrink-0">
          {icon}
        </div>
        <div className="ms-3">
          <p className={`text-sm ${textColor}`}>
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Toast;
