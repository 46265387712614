import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

export default function GetKnow() {

  const { t } = useTranslation();
  const {header, content } = t("getknow");

  useEffect(() => {
    const animations = [
      { 
        trigger: ".card1", 
        elements: [
          { 
            target: ".card1 .firstbox", 
            start: "-=40px", 
            end: "bottom top", 
            position: "left-28" 
          },
          { 
            target: ".card1 .secbox", 
            start: "+=80px", 
            end: "bottom top", 
            position: "left-32" 
          }
        ]
      },
      { 
        trigger: ".card2", 
        elements: [
          { 
            target: ".card2 .firstbox", 
            start: "+=30px", 
            end: "bottom top", 
            position: "right-60" 
          },
          { 
            target: ".card2 .secbox", 
            start: "-=60px", 
            end: "bottom top", 
            position: "right-24" 
          }
        ]
      },
      { 
        trigger: ".card3", 
        elements: [
          { 
            target: ".card3 .firstbox", 
            start: "-=40px", 
            end: "bottom top", 
            position: "left-32" 
          },
          { 
            target: ".card3 .secbox", 
            start: "+=80px", 
            end: "bottom top", 
            position: "left-28" 
          }
        ]
      }
    ];

    animations.forEach(({ trigger, elements }) => {
      elements.forEach(({ target, start, end, position }) => {
        gsap.fromTo(target, 
          { x: start }, 
          { 
            x: position, 
            scrollTrigger: {
              trigger: trigger,
              start: "top bottom",
              end: end,
              scrub: true
            }
          }
        );
      });
    });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-20">
        <div className="text-white flex flex-col gap-5 sm:p-14 md:p-20 pt-10 md:pt-0 pb-0 sm:pb-0 md:pb-0">
          <h1 className="text-xl sm:text-3xl lg:text-4xl font-lato font-light text-center">{header}</h1>
          <p className="md:mt-6 mt-4 px-7 md:px-20 font-light text-xs md:text-xl text-justify sm:text-center font-josefin">{content}
          </p>
        </div>

        {/* <div className="card1 md:mx-20 rounded-xl mb-0 md:mb-20 relative flex justify-center font-lato font-light">
          <img src="/assets/businessman.jpg" alt="businessman" className="w-full rounded-xl h-auto max-w-[90%] max-h-[400px] min-h-[250px]" />
          <div className="firstbox hidden text-white absolute md:flex flex-col gap-4 max-w-[150px] max-h-[150px] md:max-w-[300px] md:max-h-[300px] h-[150px] w-[250px] py-5 px-8 items-start bg-[rgba(217,217,217,0.3)] bottom-6 md:bottom-14 md:left-56 sm:left-16 z-0 backdrop-blur-sm"></div>
          <div className="secbox text-white absolute flex flex-col md:gap-4 gap-3 max-w-[200px] md:max-w-[300px] py-3 md:py-5 px-4 md:px-8 items-start bg-[rgba(217,217,217,0.3)] md:bottom-[-70px] bottom-[-40px] md:left-32 left-16 z-10 backdrop-blur-sm">
            <p className="text-xs md:text-xl">Explore</p>
            <h1 className="md:text-2xl text-base font-normal">ERP & CRM</h1>
            <p className="text-left text-xs">Streamlining business operations with integrated ERP and CRM solutions for efficiency.</p>
            <button className="btn bg-[rgba(4,38,123,0.5)] py-1 px-3 sm:px-4 text-sm md:mb-5 mb-2">Explore</button>
          </div>
        </div>

        <div className="card2 md:mx-20 rounded-xl mb-0 md:mb-20 relative font-lato font-light flex justify-center">
          <img src="/assets/robo.jpg" alt="robo ai" className="w-full rounded-xl h-auto max-h-[400px] scale-x-[-1] max-w-[90%] min-h-[250px]" />
          <div className="firstbox text-white absolute hidden md:flex flex-col gap-4 max-w-[150px] max-h-[150px] md:max-w-[300px] md:max-h-[300px] h-[150px] w-[250px] py-5 px-8 items-start bg-[rgba(217,217,217,0.3)] md:bottom-16 bottom-10 md:right-60 right-10 z-0 backdrop-blur-sm"></div>
          <div className="secbox text-white absolute flex flex-col md:gap-4 gap-3 md:max-w-[300px] max-w-[200px] py-3 md:py-5 px-4 md:px-8 items-start bg-[rgba(217,217,217,0.3)] bottom-[-50px] right-6 md:right-36 z-10 backdrop-blur-sm">
            <p className="text-xs ms:text-xl">Explore</p>
            <h1 className="md:text-2xl text-base font-normal">Artificial Intelligence</h1>
            <p className="text-left text-xs">Empowering businesses with intelligent AI solutions for growth, efficiency, and innovation.</p>
            <button className="btn bg-[rgba(4,38,123,0.5)] py-1 px-3 sm:px-4 text-sm md:mb-5 mb-2">Explore</button>
          </div>
        </div>

        <div className="card3 md:mx-20 rounded-xl mb-0 md:mb-20 relative font-light font-lato flex justify-center">
          <img src="/assets/cloude.png" alt="cloude" className="w-full rounded-xl h-auto max-h-[400px] max-w-[90%] min-h-[250px]" />
          <div className="firstbox text-white absolute hidden md:flex flex-col gap-4 max-w-[150px] max-h-[150px] md:max-w-[300px] md:max-h-[300px] h-[150px] w-[250px] py-5 px-8 items-start bg-[rgba(217,217,217,0.3)] bottom-16 left-28 md:left-52 z-0 backdrop-blur-sm"></div>
          <div className="secbox text-white absolute flex flex-col md:gap-4 gap-3 md:max-w-[300px] max-w-[200px] py-5 px-8 items-start bg-[rgba(217,217,217,0.3)] md:bottom-[-60px] bottom-[-40px] left-16 md:left-28 z-10 backdrop-blur-sm">
            <p className="text-xs ms:text-xl">Explore</p>
            <h1 className="md:text-2xl text-base font-normal">Cloud Computing</h1>
            <p className="text-left text-xs">Empowering your business with scalable, secure, and efficient cloud computing solutions.</p>
            <button className="btn bg-[rgba(4,38,123,0.5)] py-1 px-3 sm:px-4 text-sm md:mb-5 mb-2">Explore</button>
          </div>
        </div> */}
      </div>
    </>
  );
}
