import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function DashBoard() {
    const [demoRequests, setDemoRequests] = useState([]);
    const [enquiries, setEnquiries] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Filter only new (unread) demo requests
    const newDemoRequests = demoRequests.filter((request) => !request.is_read);
    // Filter only new (unread) enquiries
    const newEnquiries = enquiries.filter((enquiry) => !enquiry.is_read);

    // Combine the new demo requests and enquiries into one array
    const combinedRequests = [...newDemoRequests, ...newEnquiries];

    // Limit to a maximum of 3 new requests
    const topRequests = combinedRequests.slice(0, 6);

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("accessToken");

            if (!token) {
                setError("No access token found. Redirecting to login...");
                setLoading(false);
                window.location.href = "/admin"; // Navigate to the login page
                return;
            }

            try {
                // Fetch demo requests data
                const demoResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/admin/demo/demo-requests?page=1&limit=500&order=asc`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                const demoData = await demoResponse.json();

                if (demoResponse.ok && demoData.success) {
                    setDemoRequests(demoData.data.data);
                } else {
                    setError(demoData.msg || "Failed to fetch demo requests.");
                    navigate("/admin");
                }

                // Fetch enquiry data
                const enquiryResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/admin/contact-us-list?page=1&limit=15&order=asc`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                const enquiryData = await enquiryResponse.json();

                if (enquiryResponse.ok && enquiryData.success) {
                    setEnquiries(enquiryData.data.data);
                } else {
                    setError(enquiryData.msg || "Failed to fetch enquiries.");
                    navigate("/admin");
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    return (
        <div className="w-full">
            <div className="flex-grow p-6">
                <h2 className="text-2xl font-semibold text-left mb-4">DashBoard</h2>

                {loading ? (
                    <p className="text-gray-600">Loading...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <div>
                        <div className='text-left font-lighter font-josefin mt-10'>New Requests</div>
                        {topRequests.length > 0 ? (
                            <div className="py-5 overflow-hidden card_container flex justify-center items-center flex-col gap-3">
                                {topRequests.map((item) => (
                                    <div
                                        key={item.id}
                                        className="bg-white rounded-lg w-full flex items-center justify-between px-5 py-4 shadow-lg"
                                    >
                                        <div className="flex gap-2">
                                            {item.company_name ? <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="icon icon-tabler icon-tabler-building"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M3 21l18 0" />
                                                <path d="M9 8l1 0" />
                                                <path d="M9 12l1 0" />
                                                <path d="M9 16l1 0" />
                                                <path d="M14 8l1 0" />
                                                <path d="M14 12l1 0" />
                                                <path d="M14 16l1 0" />
                                                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                                            </svg> :
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="icon icon-tabler icons-tabler-outline icon-tabler-user"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                                </svg>
                                            }

                                            <h6 className='text-left'>{item.company_name || item.name}</h6>
                                        </div>

                                        {/* Show "New" label if is_read is false */}
                                        {!item.is_read && (
                                            <div className="bg-green-500 px-2 py-1 rounded-full text-white text-[10px]">New</div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No new requests or enquiries</p>
                        )}

                        {/* View All button */}
                        <div className="flex justify-between sm:justify-end gap-3">
                            <button
                                className="px-5 py-2 bg-blue-800 hover:bg-blue-900 text-white rounded-lg"
                                onClick={() => navigate('/admin/requests')}
                            >
                                View Requests
                            </button>
                            <button
                                className="px-5 py-2 bg-blue-800 hover:bg-blue-900 text-white rounded-lg"
                                onClick={() => navigate('/admin/enquiry')}
                            >
                                View Enquirys
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
