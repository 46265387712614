import React, { useEffect } from 'react'
import JoinNow from '../home/joinNow'
import Footer from '../home/Footer'
import { useTranslation } from 'react-i18next';

export default function ContactUs() {

    const { t } = useTranslation();
    const {main_header } = t("joinnow");

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='min-h-screen pt-[100px] text-white'>
            <h1 className='font-lato font-light text-xl sm:text-3xl text-center lg:text-4xl'>{main_header}</h1>
            <JoinNow />
            <div className='px-6 md:px-20 md:mt-20 mt-10'>
                <Footer />
            </div>

        </div>
    )
}
