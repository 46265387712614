import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../home/Footer';
import { IconAssembly, IconChartBubble, IconPackages } from '@tabler/icons-react';
import SolutionBlog from './sol_blog';
import { useTranslation } from 'react-i18next';

export default function SingleSolution() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('item');
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  // Memoized solutions data that updates with language changes
  const solutionsData = useMemo(() => ({
    1: {
      header: t("solDetails.erp.header"),
      headContent: t("solDetails.erp.headContent"),
      box1head: t("solDetails.erp.box1head"),
      box1content: t("solDetails.erp.box1content"),
      box2head: t("solDetails.erp.box2head"),
      box2content: t("solDetails.erp.box2content"),
      box3head: t("solDetails.erp.box3head"),
      box3content: t("solDetails.erp.box3content"),
      image: "/assets/errp.jpg"
    },
    2: {
      header: t("solDetails.ecom.header"),
      headContent: t("solDetails.ecom.headContent"),
      box1head: t("solDetails.ecom.box1head"),
      box1content: t("solDetails.ecom.box1content"),
      box2head: t("solDetails.ecom.box2head"),
      box2content: t("solDetails.ecom.box2content"),
      box3head: t("solDetails.ecom.box3head"),
      box3content: t("solDetails.ecom.box3content"),
      image: "/assets/isometric.jpg"
    },
    3: {
      header: t("solDetails.pos.header"),
      headContent: t("solDetails.pos.headContent"),
      box1head: t("solDetails.pos.box1head"),
      box1content: t("solDetails.pos.box1content"),
      box2head: t("solDetails.pos.box2head"),
      box2content: t("solDetails.pos.box2content"),
      box3head: t("solDetails.pos.box3head"),
      box3content: t("solDetails.pos.box3content"),
      image: "/assets/pos_Thumbnail.jpg"
    },
    4: {
      header: t("solDetails.digital.header"),
      headContent: t("solDetails.digital.headContent"),
      box1head: t("solDetails.digital.box1head"),
      box1content: t("solDetails.digital.box1content"),
      box2head: t("solDetails.digital.box2head"),
      box2content: t("solDetails.digital.box2content"),
      box3head: t("solDetails.digital.box3head"),
      box3content: t("solDetails.digital.box3content"),
      image: "/assets/internet.jpg"
    },
    5: {
      header: t("solDetails.app.header"),
      headContent: t("solDetails.app.headContent"),
      box1head: t("solDetails.app.box1head"),
      box1content: t("solDetails.app.box1content"),
      box2head: t("solDetails.app.box2head"),
      box2content: t("solDetails.app.box2content"),
      box3head: t("solDetails.app.box3head"),
      box3content: t("solDetails.app.box3content"),
      image: "/assets/mobileapp.jpg"
    },
    6: {
      header: t("solDetails.hrms.header"),
      headContent: t("solDetails.hrms.headContent"),
      box1head: t("solDetails.hrms.box1head"),
      box1content: t("solDetails.hrms.box1content"),
      box2head: t("solDetails.hrms.box2head"),
      box2content: t("solDetails.hrms.box2content"),
      box3head: t("solDetails.hrms.box3head"),
      box3content: t("solDetails.hrms.box3content"),
      image: "/assets/hrms1.png"
    }
  }), [t, i18n.language]); // Add i18n.language to dependencies

  const [data, setData] = useState(null);

  const truncateText = (text, maxWords) => {
    const words = text.split(' ');
    return words.length > maxWords
      ? words.slice(0, maxWords).join(' ') + '...'
      : text;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const solutionData = solutionsData[id];
    if (solutionData) {
      setData(solutionData);
    }
  }, [id, solutionsData]); // Add solutionsData to dependencies

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className='text-white min-h-screen pt-[90px] px-7 md:px-20'>
      <div className='lg:px-52'>
        <h1 className='font-lato font-light text-xl sm:text-2xl lg:text-3xl text-center'>{data.header}</h1>
        <p className='md:mt-6 mt-4 font-light text-xs md:text-xl text-justify sm:text-center font-josefin text-gray-400'>{data.headContent}</p>
      </div>
      <div className='head flex mt-10 mb-16 flex-col-reverse mmd:flex-row gap-y-10'>
        <div className='lg:w-[60%] w-full flex flex-col gap-7 items-end'>
          <div className='flex rounded-xl overflow-hidden w-full'>
            <div className='min-w-[100px] flex-shrink-0 bg-white text-black flex justify-center items-center'>
              <IconAssembly stroke={2} className='w-3/4 h-3/4' />
            </div>
            <div className='py-2 px-3 flex-grow bg-gray-500 flex flex-col gap-y-3'>
              <h1 className={`text-left font-normal font-josefin ${isMobile ? 'truncate-boxhead' : ''}`}>
                {isMobile ? truncateText(data.box1head, 3) : data.box1head}
              </h1>
              <p className='text-left sm:text-base text-xs font-light font-josefin text-gray-300 text'>
                {isMobile ? truncateText(data.box1content, 12) : data.box1content}
              </p>
            </div>
          </div>

          {/* Second Box */}
          <div className='flex rounded-xl overflow-hidden w-full'>
            <div className='min-w-[100px] flex-shrink-0 bg-white text-black flex justify-center items-center'>
              <IconChartBubble stroke={2} className='w-3/4 h-3/4' />
            </div>
            <div className='py-2 px-3 flex-grow bg-gray-500 flex flex-col gap-y-3'>
              <h1 className='text-left font-normal font-josefin'>
                {isMobile ? truncateText(data.box2head, 3) : data.box2head}
              </h1>
              <p className='text-left sm:text-base text-xs font-light font-josefin text-gray-300'>
                {isMobile ? truncateText(data.box2content, 12) : data.box2content}
              </p>
            </div>
          </div>

          {/* Third Box */}
          <div className='flex rounded-xl overflow-hidden w-full'>
            <div className='min-w-[100px] flex-shrink-0 bg-white text-black flex justify-center items-center'>
              <IconPackages stroke={2} className='w-3/4 h-3/4' />
            </div>
            <div className='py-2 px-3 flex-grow bg-gray-500 flex flex-col gap-y-3'>
              <h1 className='text-left font-normal font-josefin'>
                {isMobile ? truncateText(data.box3head, 3) : data.box3head}
              </h1>
              <p className='text-left sm:text-base text-xs font-light font-josefin text-gray-300'>
                {isMobile ? truncateText(data.box3content, 12) : data.box3content}
              </p>
            </div>
          </div>
        </div>

        {/* Image Section */}
        <div className='lg:w-[40%] w-full flex items-center justify-center'>
          {!isImageLoaded && (
            <div className="animate-pulse bg-gray-200 h-[300px] w-full max-w-[330px] md:w-[450px] rounded-xl"></div>
          )}
          <img
            src={data.image}
            alt={data.header}
            className={`rounded-xl mmd:max-w-[330px] md:w-[450px] ${isImageLoaded ? 'block' : 'hidden'}`}
            onLoad={() => setIsImageLoaded(true)}
            style={{ objectFit: 'cover' }}
          />
        </div>
      </div>
      <SolutionBlog id={id} />

      <Footer />
    </div>
  );
}