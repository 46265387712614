import React from 'react'
import { useTranslation } from 'react-i18next';

export default function SolutionBlog({ id }) {

    const { t } = useTranslation();
  const {ecom, pos, hrms } = t("blog");


    const blogData = {
        // 1: {
        //     image1: '/assets/pos1.png',
        //     header1: 'Opening Cash Control Screen',
        //     content1: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        //     image2: '/assets/pos2.png',
        //     header2: 'Product Screen and Cart',
        //     content2: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        //     image3: '/assets/pos3.png',
        //     header3: 'Payment Screen',
        //     content3: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        //     image4: '/assets/pos4.png',
        //     header4: 'Bill Screen',
        //     content4: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        // },
        2: {
            image1: '/assets/ecom1.jpeg',
            header1: ecom.header1,
            content1: ecom.content1,
            image2: '/assets/ecom2.jpeg',
            header2: ecom.header2,
            content2: ecom.content2,
            image3: '/assets/ecom3.jpeg',
            header3: ecom.header3,
            content3: ecom.content3,
            image4: '/assets/ecom4.jpeg',
            header4: ecom.header4,
            content4: ecom.content4,
        },
        3: {
            image1: '/assets/pos1.png',
            header1: pos.header1,
            content1: pos.content1,
            image2: '/assets/pos2.png',
            header2: pos.header2,
            content2: pos.content2,
            image3: '/assets/pos3.png',
            header3: pos.header3,
            content3: pos.content3,
            image4: '/assets/pos4.png',
            header4: pos.header4,
            content4: pos.content4,
        },
        6: {
            image1: '/assets/hr1.png',
            header1: hrms.header1,
            content1: hrms.content1,
            image2: '/assets/hr2.png',
            header2: hrms.header2,
            content2: hrms.content2,
            image3: '/assets/hr3.png',
            header3: hrms.header3,
            content3: hrms.content3,      
            image4: '/assets/hr4.png',
            header4: hrms.header4,
            content4: hrms.content4,
        }
        
    }

    const selectedBlog = blogData[id];

    if (!selectedBlog) {
        return <></>;
    }

    return (
        <div className="grid gap-x-8 gap-y-10 md:mt-28 mt-10 md:grid-cols-2">
            {Object.keys(selectedBlog)
                .filter((key) => key.startsWith('image'))
                .map((imageKey, index) => (
                    <div
                        key={imageKey}
                        className="p-4 flex flex-col items-center bg-gray-500 shadow-md rounded-lg"
                    >
                        <img
                            src={selectedBlog[imageKey]}
                            alt={`Content ${index + 1}`}
                            className="w-full h-48 object-cover mb-4 rounded-md"
                        />
                        <h1 className='mb-2 text-lg font-light'>{selectedBlog[`header${index+1}`]}</h1>
                        <p className="text-gray-300 text-justify font-light">{selectedBlog[`content${index + 1}`]}</p>
                    </div>
                ))}
        </div>

    );
}