import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Footer() {

  
  const { t } = useTranslation();
  const { company_name, social, company, links,resources, developes, allright } = t("footer");

  return (
    <>
    <div>
    <div className="flex flex-col pb-0 md:pb-6 md:pt-20 pt-5 w-full bg-zinc-100 bg-opacity-0 max-w-[1440px] max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-wrap md:gap-5 gap-2 justify-between w-full max-w-[1195px] max-md:max-w-full">
        <div className="flex w-full sm:w-auto flex-wrap sm:flex-col self-end mt-6 items-center mb-3 md:mb-0 gap-y-3 gap-x-3">
          <div className="md:text-3xl text-base text-white font-lato">
            <span className="font-bold">{company_name.white}</span>
            <span className="font-bold text-blue-400">{company_name.blue}</span>
            <span className="font-bold text-white">.</span>
          </div>
          <div className="md:mt-20 md:text-base text-sm leading-none text-slate-200 max-md:mr-1 md:block hidden">
            {social}{" "}
          </div>
          <div className="flex gap-5 justify-between mx-3.5 mt-1.5 max-md:mx-2.5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e700a7cd6152c56a508bbfafe1b57ff8aace5a0d8b2837f0cef27266e75171ad?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712"
              className="object-contain shrink-0 self-start aspect-[1.1] w-[23px]" alt='photos'
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a046f65f9ecb3b28fafbb8a0fdb9562ca2b739f84bf21855dd1d24fcfe1c752d?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712"
              className="object-contain shrink-0 w-6 aspect-[1.09]" alt='photos'
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e2db7b6020f0a27aba6c51f9943d85566c49f463a33a892b5860252436fc6aad?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712"
              className="object-contain shrink-0 my-auto aspect-[1.39] w-[25px]" alt='photos'
            />
          </div>
        </div>
        <div className="flex w-full sm:w-auto sm:flex-col  items-start text-base font-[275] font-josefin text-gray-400 gap-4">
          <div className="md:text-lg text-sm text-white">{company.header}</div>
          <div style={{fontSize:'clamp(10px,1.3vw,18px)'}} >{company.careers}</div>
          <div  style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{company.change}</div>
          <div  style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{company.privacy}</div>
          <div className="self-stretch"  style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{company.terms}</div>
        </div>
        <div className="flex w-full sm:w-auto sm:flex-col items-start self-start text-base whitespace-nowrap font-[275] font-josefin text-gray-400 gap-4">
          <div className="md:text-lg text-sm text-white">{links.header}</div>
          <div className="self-stretch" style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{links.market}</div>
          <div style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{links.dashboard}</div>
          <div style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{links.blog}</div>
        </div>
        <div className="flex w-full sm:w-auto sm:flex-col items-start text-base font-[275] font-josefin text-gray-400 gap-4">
          <div className="md:text-lg text-sm text-white">{resources.header}</div>
          <div style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{resources.guides}</div>
          <div style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{company.change}</div>
          <div style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{resources.support}</div>
          <div style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{company.privacy}</div>
        </div>
        <div className="flex w-full sm:w-auto sm:flex-col self-start text-base font-[275] font-josefin text-gray-400 gap-4">
          <div className="md:text-lg text-sm text-white self-start">{developes.header}</div>
          <div className="self-start" style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{company.careers}</div>
          <div style={{fontSize:'clamp(10px,1.3vw,18px)'}}>{developes.api}</div>
        </div>
      </div>
      <div className="flex shrink-0 md:mt-14 mt-6 h-px rounded bg-slate-300 max-md:max-w-full" />
      <div className="self-center text-xs leading-9 text-gray-400">
        <span className="font-medium">@taQnura 2024</span>
        <span>. {allright}</span>
      </div>
    </div>
    </div>
    </>
  )
}
