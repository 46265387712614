import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Route, Routes } from "react-router-dom";
import Requests from "./requests";
import DashBoard from "./DashBoard";
import Enquiry from "./enquiry";
import ConfirmationModal from "./confirmationModal";

export default function AdminHome() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for off-canvas sidebar
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (path) => {
        navigate(`/admin${path}`);
        setIsSidebarOpen(false); // Close sidebar after navigation
    };

    const isActive = (path) => {
        const currentPath = location.pathname.replace("/admin", ""); // Strip "/admin" prefix
        return currentPath === path;
    };

    const handleLogoutClick = () => {
        setIsModalVisible(true);
    };

    const handleConfirmLogout = () => {
        localStorage.removeItem("accessToken");
        navigate("/login");
    };

    const handleCancelLogout = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (location.pathname === "/admin") {
            navigate("/admin/dashboard");
        }
    }, [location.pathname, navigate]);

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Sidebar for larger screens */}
            <div className="hidden md:block w-64 bg-white shadow-lg">
                <SidebarContent
                    isActive={isActive}
                    handleNavigation={handleNavigation}
                    handleLogoutClick={handleLogoutClick}
                />
            </div>

            {/* Off-canvas sidebar for smaller screens */}
            {isSidebarOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
                    onClick={() => setIsSidebarOpen(false)}
                ></div>
            )}
            <div
                className={`fixed inset-y-0 left-0 transform bg-white shadow-lg z-50 w-64 transition-transform md:hidden ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    }`}
            >
                <SidebarContent
                    isActive={isActive}
                    handleNavigation={handleNavigation}
                    handleLogoutClick={handleLogoutClick}
                />
            </div>

            {/* Main content */}
            <div className="flex-1 overflow-y-auto">
                {/* Top bar with hamburger menu for small screens */}
                <div className="md:hidden bg-white shadow p-4 flex justify-start">
                    <button
                        className="text-gray-700"
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                       <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-menu-2"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 6l16 0" /><path d="M4 12l16 0" /><path d="M4 18l16 0" /></svg>    
                    </button>
                </div>

                <Routes>
                    <Route path="/dashboard" element={<DashBoard />} />
                    <Route path="/requests" element={<Requests />} />
                    <Route path="/enquiry" element={<Enquiry />} />
                    <Route path="/*" element={<DashBoard />} />
                </Routes>
            </div>

            <ConfirmationModal
                isVisible={isModalVisible}
                onConfirm={handleConfirmLogout}
                onCancel={handleCancelLogout}
            />
        </div>
    );
}

// Extracted Sidebar Content Component
function SidebarContent({ isActive, handleNavigation, handleLogoutClick }) {
    return (
        <div className="flex flex-col h-full">
            <div className="p-6">
                <h1 className="text-2xl font-semibold text-black">
                    Taq<span className="text-blue-500">nura</span>
                </h1>
            </div>
            <nav className="flex-1 overflow-y-auto mt-6">
                <ul className="flex flex-col px-5 gap-1">
                    <SidebarItem
                        label="Home"
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="icon"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                            </svg>
                        }
                        active={isActive("/dashboard")}
                        onClick={() => handleNavigation("/dashboard")}
                    />
                    <SidebarItem
                        label="Requests"
                        icon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-mail-question"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4.5" /><path d="M19 22v.01" /><path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" /><path d="M3 7l9 6l9 -6" /></svg>
                        }
                        active={isActive("/requests")}
                        onClick={() => handleNavigation("/requests")}
                    />
                    <SidebarItem
                        label="Enquirys"
                        icon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-message-question"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M8 9h8" /><path d="M8 13h6" /><path d="M14 18h-1l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5" /><path d="M19 22v.01" /><path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483" /></svg>
                        }
                        active={isActive("/enquiry")}
                        onClick={() => handleNavigation("/enquiry")}
                    />
                    <SidebarItem
                        label="Teams"
                        icon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-users-group"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1" /><path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M17 10h2a2 2 0 0 1 2 2v1" /><path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M3 13v-1a2 2 0 0 1 2 -2h2" /></svg>
                        }
                        active={isActive("/teams")}
                        onClick={() => handleNavigation("/teams")}
                    />
                    <SidebarItem
                        label="Settings"
                        icon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-settings"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" /></svg>
                        }
                        active={isActive("/settings")}
                        onClick={() => handleNavigation("/settings")}
                    />

                </ul>
            </nav>
            <div className="p-6 text-sm text-gray-500">
                <div
                    className="flex items-center gap-2 mb-4 cursor-pointer hover:text-gray-700"
                    onClick={handleLogoutClick}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                        <path d="M9 12h12l-3 -3" />
                        <path d="M18 15l3 -3" />
                    </svg>
                    Log out
                </div>
            </div>
        </div>
    );
}

function SidebarItem({ label, icon, active, onClick }) {
    return (
        <li
            className={`flex items-center gap-4 px-6 py-3 rounded-md cursor-pointer ${active ? "bg-blue-950 text-white" : "text-gray-700 hover:bg-blue-950 hover:text-white"
                }`}
            onClick={onClick}
        >
            {icon}
            {label}
        </li>
    );
}
