import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataModal from "./dataModal";

export default function Requests() {
    const navigate = useNavigate();
    const [demoRequests, setDemoRequests] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);
    const [expandedItems, setExpandedItems] = useState(new Set());
    const [modalDataId, setModalDataId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Track the current page
    const [totalPages, setTotalPages] = useState(1); // Total pages from API

    const ITEMS_PER_PAGE = 15;

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("accessToken");

            if (!token) {
                setError("No access token found. Redirecting to login...");
                setLoading(false);
                window.location.href = "/admin";
                return;
            }

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/admin/demo/demo-requests?page=${currentPage}&limit=${ITEMS_PER_PAGE}&order=asc`,
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                const data = await response.json();

                if (response.ok && data.success) {
                    // Sort requests with unread items first
                    const sortedRequests = data.data.data.sort((a, b) => {
                        if (a.is_read === b.is_read) return 0;
                        return a.is_read ? 1 : -1;
                    });
                    setDemoRequests(sortedRequests);
                    setTotalPages(data.data.totalPages); // Update total pages
                } else {
                    setError(data.msg || "Failed to fetch demo requests.");
                    navigate("/admin");
                }
            } catch (err) {
                setError("An error occurred while fetching data.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate, currentPage]); // Re-fetch data when currentPage changes

    const toggleCollapse = (id) => {
        setExpandedItems((prev) => {
            const newExpandedItems = new Set(prev);
            if (newExpandedItems.has(id)) {
                newExpandedItems.delete(id);
            } else {
                newExpandedItems.add(id);
            }
            return newExpandedItems;
        });
    };

    const markAsReadInList = (id) => {
        setDemoRequests((prevRequests) =>
            prevRequests.map((request) =>
                request.id === id ? { ...request, is_read: true } : request
            )
        );
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <div className="w-full font-lato">
            
            <div className="flex-grow p-6">
                <h2 className="text-2xl text-start font-semibold font-josefin ">Requests</h2>

                {loading ? (
                    <p className="text-gray-600">Loading...</p>
                ) : error ? (
                    <p className="text-red-500">{error}</p>
                ) : (
                    <div>
                        <div className="py-5 overflow-hidden card_container flex justify-center items-center flex-col gap-3">
                            {demoRequests.map((item) => {
                                const isOpen = expandedItems.has(item.id);
                                return (
                                    <div
                                        key={item.id}
                                        className="bg-white rounded-lg w-full flex flex-col justify-between px-5 py-4 shadow-lg"
                                    >
                                        <div
                                            className="flex justify-between items-center cursor-pointer"
                                            onClick={() => toggleCollapse(item.id)}
                                        >
                                            <div className="flex gap-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="icon icon-tabler icons-tabler-outline icon-tabler-buildings"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4 21v-15c0 -1 1 -2 2 -2h5c1 0 2 1 2 2v15" />
                                                    <path d="M16 8h2c1 0 2 1 2 2v11" />
                                                    <path d="M3 21h18" />
                                                    <path d="M10 12v0" />
                                                    <path d="M10 16v0" />
                                                    <path d="M10 8v0" />
                                                    <path d="M7 12v0" />
                                                    <path d="M7 16v0" />
                                                    <path d="M7 8v0" />
                                                    <path d="M17 12v0" />
                                                    <path d="M17 16v0" />
                                                </svg>
                                                <h6 className="text-left">{item.company_name}</h6>
                                            </div>
                                            {!item.is_read && (
                                                <div className="bg-green-500 px-2 py-1 rounded-full text-white text-[10px]">New</div>
                                            )}
                                        </div>
                                        <div
                                            className={`overflow-hidden transition-all duration-300 flex justify-between px-8 ${isOpen ? "max-h-96" : "max-h-0"
                                                }`}
                                            style={{ transitionProperty: "max-height" }}
                                        >
                                            <div className="text-left my-3">
                                                <p><span className="text-gray-500">Industry: </span>{item.industry}</p>
                                                <p><span className="text-gray-500">Name: </span>{item.contact_name}</p>
                                                <p><span className="text-gray-500">Email: </span>{item.email}</p>
                                                <p><span className="text-gray-500">Phone: </span>{item.mobile}</p>
                                                <button
                                                    className="mt-3 px-10 font-josefin py-1 bg-blue-300 rounded-md text-sm"
                                                    onClick={() => setModalDataId(item.id)}
                                                >
                                                    Open
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {/* Pagination Controls */}
                        <div className="flex justify-between items-center mt-4">
                            <button
                                className={`px-4 py-2 bg-gray-300 rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span className="text-gray-600">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                className={`px-4 py-2 bg-gray-300 rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
            </div>

            {modalDataId && (
                <DataModal
                    id={modalDataId}
                    onClose={() => setModalDataId(null)}
                    markAsReadInList={markAsReadInList}
                />
            )}
        </div>
    );
}
