import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useEffect, useState } from 'react';
import Footer from '../home/Footer';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const CardSkeleton = () => (
  <div className="animate-pulse bg-gray-300 rounded-xl w-full h-[250px] md:h-[450px]"></div>
);

const Card = ({ index, id, imageUrl, title, description }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const { t } = useTranslation();
  const { explore } = t("services");

  useEffect(() => {
    const img = new Image();
    img.onload = () => setImageLoaded(true);
    img.src = imageUrl;
  }, [imageUrl]);

  const isEven = index % 2 === 0;

  return (
    <div className={`card${index + 1} rounded-xl mb-0 md:mb-20 relative flex justify-center font-lato font-light`}>
      {!imageLoaded && <CardSkeleton />}
      <img
        src={imageUrl}
        alt={title}
        className={`w-full rounded-xl h-auto max-h-[450px] min-h-[250px] ${imageLoaded ? 'block' : 'hidden'}`}
        onLoad={() => setImageLoaded(true)}
      />
      <div className={`firstbox hidden text-white absolute md:flex flex-col gap-4 max-w-[150px] max-h-[150px] md:max-w-[300px] md:max-h-[300px] h-[150px] w-[250px] py-5 px-8 items-start bg-[rgba(217,217,217,0.3)] bottom-6 md:bottom-14 ${isEven ? 'md:right-60 right-10' : 'md:left-56 sm:left-16'} z-0 backdrop-blur-sm`}></div>
      <div className={`secbox text-white absolute flex flex-col md:gap-4 gap-3 max-w-[200px] md:max-w-[300px] py-3 md:py-5 px-4 md:px-8 items-start bg-[rgba(217,217,217,0.3)] md:bottom-[-70px] bottom-[-40px] ${isEven ? 'right-6 md:right-36' : 'left-16 md:left-32'} z-10 backdrop-blur-sm`}>
        <p className="text-xs md:text-xl">{explore}</p>
        <h1 className="md:text-2xl text-sm font-normal text-start">{title}</h1>
        <p className="text-left text-xs md:text-base">{description}</p>
        <Link
          to={`/${title.replace(/\s+/g, '-').toLowerCase()}/solutions?item=${id}`}>
          <button className="btn bg-[rgba(4,38,123,0.5)] py-1 px-3 sm:px-4 text-sm md:mb-5 mb-2">{explore}</button>
        </Link>
      </div>
    </div>
  );
};

export default function Services() {

  const { t } = useTranslation();
  const { header, content, card } = t("services");

  useEffect(() => {
    const setupAnimations = () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());

      const cards = document.querySelectorAll('.card1, .card2, .card3, .card4, .card5');

      cards.forEach((card, index) => {
        const isEven = index % 2 === 0;
        const firstBox = card.querySelector('.firstbox');
        const secBox = card.querySelector('.secbox');

        gsap.fromTo(firstBox,
          { x: isEven ? '30px' : '-30px' },
          {
            x: isEven ? 'right-60' : 'left-56',
            scrollTrigger: {
              trigger: card,
              start: 'top bottom',
              end: 'bottom top',
              scrub: 1,
            }
          }
        );

        gsap.fromTo(secBox,
          { x: isEven ? '-30px' : '30px' },
          {
            x: isEven ? 'right-36' : 'left-32',
            scrollTrigger: {
              trigger: card,
              start: 'top bottom',
              end: 'bottom top',
              scrub: 1,
            }
          }
        );
      });
    };

    setupAnimations();

    window.addEventListener('resize', setupAnimations);

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      window.removeEventListener('resize', setupAnimations);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardData = [
    {
      id: 1,
      imageUrl: "/assets/serviceImg/erp_e_commerce.png",
      title: card.erp.header,
      description: card.erp.description
    },
    {
      id: 2,
      imageUrl: "/assets/serviceImg/e_commerce.png",
      title: card.ecom.header,
      description: card.ecom.description
    },
    {
      id: 5,
      imageUrl: "/assets/serviceImg/mobileapp.jpeg",
      title: card.app.header,
      description: card.app.description
    },
    {
      id: 3,
      imageUrl: "/assets/serviceImg/pos.jpeg",
      title: card.pos.header,
      description: card.pos.description
    },
    {
      id: 6,
      imageUrl: "/assets/serviceImg/hrms.png",
      title: card.hrms.header,
      description: card.hrms.description
    }
  ];

  return (
    <div className="text-white min-h-screen pt-[100px] px-7 md:px-20 flex flex-col md:gap-y-20 gap-y-28">
      <div className="head md:px-12">
        <p className="font-lato font-light text-xl sm:text-3xl lg:text-4xl text-center">{header}</p>
        <p className="md:mt-6 mt-4 font-light md:text-xl text-base text-justify sm:text-center font-josefin">
          {content}
        </p>
      </div>

      {cardData.map((card, index) => (
        <Card key={index} index={index} {...card} />
      ))}

      <Footer />
    </div>
  );
}