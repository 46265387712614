import React from "react";
import Footer from "../home/Footer";
import { useNavigate } from "react-router-dom";

export default function ProductListing() {
    const navigate = useNavigate();

    const cardData = [
        {
            id: 1,
            plan_name: "Basic Plan",
            price: "149/month",
            billed: "Billed annually.",
            features: [
                "1 Shop",
                "2 POS",
                "Accounting Modules",
                "Purchasing Modules",
                "Inventory Modules",
            ],
            badgeColor: "bg-gradient-to-r from-green-500 to-green-700", 
        },
        {
            id: 2,
            plan_name: "Standard Plan",
            price: "599/month",
            billed: "Billed annually.",
            features: [
                "5 Shops",
                "2 POS / Shops",
                "Accounting Modules",
                "Purchasing Modules",
                "Inventory Modules",
                "Hrms Modules",
            ],
            badgeColor: "bg-gradient-to-r from-pink-500 to-pink-700", 
        },
        {
            id: 3,
            plan_name: "Premium Plan",
            price: "1199/month",
            billed: "Billed annually.",
            features: [
                "10 Shops",
                "3 POS / Shops",
                "25 VAN sale Mob_app Licence",
                "Company Website",
                "Total 7 Modules",
                "Manufacturing & Warehouse",
            ],
            badgeColor: "bg-gradient-to-r from-purple-500 to-purple-700", 
        },
    ];

    const handleChange =(id)=>{
        navigate(`/pricing/${id}`)
    }

    return (
        <div className="md:px-20 px-5 font-lato">
            <div className="md:pt-24 py-20  bg-black text-white min-h-screen w-full flex flex-col items-center relative overflow-hidden">
                <h1 className="font-lato font-light text-xl sm:text-3xl lg:text-4xl text-center md:mb-16 mb-12">
                    Our Pricing & Plans
                </h1>

                <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12">
                    {cardData.map((item) => (
                        <div
                            key={item.id}
                            className={`relative group rounded-xl shadow-lg p-8 hover:bg-[#10184368] transition-all duration-500 border-gray-500 border-2`}
                        >
                            {/* Badge Design */}
                            <div
                                className={`absolute -top-4 left-4 px-8 py-1 text-white font-semibold rounded-md ${item.badgeColor}`}
                                style={{
                                    clipPath: "polygon(0 0, 100% 0, 85% 100%, 0% 100%)",
                                }}
                            >
                                {item.plan_name}
                            </div>

                            <div className="absolute w-24 h-24 bg-blue-400 top-32 right-20 rounded-full z-0 blur-[80px]"></div>

                            <div className="z-10 pt-8">
                                <p className="text-xl z-10 font-semibold mb-2">AED {item.price}</p>
                                <p className="text-sm text-gray-300 mb-6">{item.billed}</p>
                                <ul className="mb-6 space-y-2">
                                    {item.features.map((feature, index) => (
                                        <li key={index} className="flex items-center gap-2">
                                            <span className="text-green-500">✔</span>
                                            <span className="text-gray-300 group-hover:text-white">
                                                {feature}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                <button className="w-full py-3 mt-auto bg-white text-black font-lighter rounded-md hover:bg-[#0f213b] transition-all duration-300 hover:text-white" 
                                onClick={() => handleChange(item.id)}>
                                    View Full Details
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}
