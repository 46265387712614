import React from "react";

export default function DemoButton({ openModal }) {
  return (
    <div className="tooltip-container">
      <span className="tooltip">Request For Demo</span>
      <span className="text">
        <div className="borde-back">
          <div 
            className="icon" 
            onClick={openModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M11 11h2v2h-2z" />
              <path d="M3.634 15.634l1.732 -1l1 1.732l-1.732 1z" />
              <path d="M11 19h2v2h-2z" />
              <path d="M18.634 14.634l1.732 1l-1 1.732l-1.732 -1z" />
              <path d="M17.634 7.634l1.732 -1l1 1.732l-1.732 1z" />
              <path d="M11 3h2v2h-2z" />
              <path d="M3.634 8.366l1 -1.732l1.732 1l-1 1.732z" />
            </svg>
          </div>
        </div>
      </span>

      <style jsx>{`
        .tooltip-container {
          position: relative;
          background-color: inherit;
          cursor: pointer;
          transition: all 0.2s;
          font-size: 17px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          fill: #fff;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.18);
          position: fixed;
          bottom: 20px;
          right: 20px;
          z-index: 50;
        }

        .tooltip-container .borde-back {
          width: 70px;
          height: 70px;
          background-color: rgba(248, 250, 252, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          box-shadow: none;
        }

        .tooltip-container .icon {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          background-color: #6b7280;
          cursor: pointer;
          transform: rotate(360deg);
          transition: 0.4s ease-in-out;
        }

        .tooltip {
          position: absolute;
          top: 50%;
          transform: translateY(-50%) scaleX(1);
          transform-origin: right center;
          right: 100%;
          margin-right: 10px;
          height: 50px;
          white-space: nowrap;
          opacity: 1;
          pointer-events: auto;
          transition: all 0.6s;
          border-radius: 50px 0 0 50px;
          background-color: #6b7280;
          display: flex;
          align-items: center;
          justify-content: left;
          padding-left: 16px;
          color: #fff;
          font-size: 15px;
          font-family: sans-serif;
          font-weight: 400;
          padding-right: 40px;
          margin-right: -25px;
        }

        /* Adjust Sizes for Smaller Screens */
        @media (max-width: 768px) {
          .tooltip-container {
            width: 50px;
            height: 50px;
            bottom: 15px;
            right: 15px;
          }

          .tooltip-container .borde-back {
            width: 60px;
            height: 60px;
          }

          .tooltip-container .icon {
            width: 50px;
            height: 50px;
          }

          .tooltip {
            font-size: 13px;
            padding-left: 12px;
            padding-right: 30px;
          }
        }

        @media (max-width: 480px) {
          .tooltip-container {
            width: 40px;
            height: 40px;
            bottom: 10px;
            right: 10px;
          }

          .tooltip-container .borde-back {
            width: 50px;
            height: 50px;
          }

          .tooltip-container .icon {
            width: 40px;
            height: 40px;
          }

          .tooltip {
            font-size: 12px;
            padding-left: 10px;
            padding-right: 25px;
          }
        }
      `}</style>
    </div>
  );
}
