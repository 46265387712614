import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Modal from "../src/pages/home/modal";
import Toast from "../src/pages/home/toast";
import "./App.css";
import Home from "./pages/home/home";
import Navbar from "./pages/home/navbar";
import About from "./pages/about/About";
import Services from "./pages/services/Services";
import Solutions from "./pages/solutions/Solutions";
import SingleSolution from "./pages/solutions/solDetails";
import ContactUs from "./pages/dropdown/contactus";
import Blog from "./pages/dropdown/blog";
import FaQs from "./pages/dropdown/faQs";
import Support from "./pages/dropdown/support";
import Careers from "./pages/dropdown/careers";
import Login from "./pages/Admin/Login";
import AdminHome from "./pages/Admin/adminHome";
import DemoButton from "./pages/home/demo";
import AnimationDiv from "./pages/home/animationDiv";
import ProductListing from "./pages/products/listing";
import SinglePlan from "./pages/products/singlePlan";



function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    type: "success",
    message: "",
  });
  const [formData, setFormData] = useState({
    company_name: "",
    industry: "",
    web: "",
    email: "",
    contact_name: "",
    designation: "",
    mobile: "",
    whatsapp: "",
    product_interests: "",
    company_description: "",
    country: "",
    city: "",
    state:""
  });



  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitForm = async (formData) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/demo/request-demo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setToast({
          show: true,
          type: "success",
          message: "Form submitted successfully",
        });
        setFormData({
          company_name: "",
          industry: "",
          web: "",
          email: "",
          contact_name: "",
          designation: "",
          mobile: "",
          whatsapp: "",
          product_interests: "",
          company_description: "",
          city: "",
          country: "",
          state:"",
        });
        closeModal();
      } else {
        setToast({
          show: true,
          type: "error",
          message: "Something went wrong",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setToast({
        show: true,
        type: "error",
        message: "Error submitting form",
      });
    }
  };

  const handleCloseToast = () => {
    setToast({ show: false, type: "success", message: "" });
  };

  return (
    <div className="App bg-black relative overflow-hidden">

      <Routes>
        {/* Routes with Navbar */}
        <Route path="/" element={<> <Navbar /> <Navigate to="/home" replace /> </>} />
        <Route path="/home" element={<> <Navbar /> <Home /> <AnimationDiv /> <DemoButton openModal={openModal} /> </>} />
        <Route path="/about" element={<> <Navbar /> <About /> <AnimationDiv /> </>} />
        <Route path="/services" element={<> <Navbar /> <AnimationDiv /> <Services />  </>} />
        <Route path="/solutions" element={<> <Navbar /> <AnimationDiv /> <Solutions /> </>} />
        <Route path="/:title/solutions" element={<> <Navbar /> <AnimationDiv /> <SingleSolution /> </>} />
        <Route path="/contact" element={<> <Navbar /> <AnimationDiv /> <ContactUs /> </>} />
        <Route path="/blog" element={<> <Navbar /> <AnimationDiv /> <Blog /> </>} />
        <Route path="/careers" element={<> <Navbar /> <AnimationDiv /> <Careers /> </>} />
        <Route path="/faQs" element={<> <Navbar /> <AnimationDiv /> <FaQs /> </>} />
        <Route path="/support" element={<> <Navbar /> <AnimationDiv /> <Support /> </>} />
        <Route path="/pricing" element={<> <Navbar /> <AnimationDiv /> <ProductListing /> </>} />
        <Route path="/pricing/:id" element={<> <Navbar /> <AnimationDiv /> <SinglePlan /> </>} />
        {/* Admin Route without Navbar */}
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/*" element={<AdminHome />} />

        {/* Fallback Route */}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>

      {/* Toast Notification */}
      {toast.show && (
        <Toast
          type={toast.type}
          message={toast.message}
          onClose={handleCloseToast}
        />
      )}

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        formData={formData}
        handleChange={handleChange}
        handleSubmitForm={handleSubmitForm}
      />
    </div>
  );
}

export default App;
